import React from 'react'

export const DataContext = React.createContext();

export class DataProvider extends React.Component {

    state = {
        total: 0,
        cartReserv :[]
    };

    addCart = (objetReserv) => {
        const { cartReserv } = this.state;
      //  let objetReserv = {uuid, dateStart,dateEnd,userId,customerEmail, phoneNumber,dateCreate,_resourceIds,contactMode,status,totalPrice,quantity}
        cartReserv.push(objetReserv);
        this.setState({cartReserv: [...cartReserv]});
       
    }

    viderCart = ()=> {
        const {cartReserv} = this.state;
        cartReserv.splice(0, cartReserv.length);
        this.setState({cartReserv: cartReserv});
    }
    removeCart = (id) => {
        const {cartReserv} = this.state;
        for (let i = 0; i < cartReserv.length; i++) {
            if(cartReserv[i].uuid === id){
                cartReserv.splice(i, 1);
                this.setState({cartReserv: cartReserv});
                setTimeout(()=>{
                    this.getTotal();
                },500)
                break;
            }
        }
    };

    getTotal = ()=>{
        const { cartReserv } = this.state;
        const res = cartReserv.reduce((prev, item) => {
            return prev + item.totalPrice ;
        },0)
        this.setState({total: res})
    };

    componentDidUpdate(){
        localStorage.setItem('dataCartReserv', JSON.stringify(this.state.cartReserv));
    };

    componentDidMount(){
        const dataTotal = JSON.parse(localStorage.getItem('dataTotal'));
        if(dataTotal !== null){
            this.setState({total: dataTotal});
        }
        const dataCartReserv = JSON.parse(localStorage.getItem('dataCartReserv'));
        if(dataCartReserv !== null){
            this.setState({cartReserv: dataCartReserv});
        }
    }

    render() {
        const {total,cartReserv} = this.state;
        const {addCart,removeCart,getTotal, viderCart} = this;
        return (
            <DataContext.Provider
                value={{addCart,cartReserv,removeCart, viderCart,total,getTotal}}>
                {this.props.children}
            </DataContext.Provider>
        )
    }
}


