import { lazy } from 'react';

const routes = [

    {
        path: 'paiement-bancaire-par-carte',
        component: lazy(() => import('../components/StripeContainer').then(({StripeContainer}) =>({default:StripeContainer}))),
        exact: true
    },

    {
        path: 'liste-des-reservations',
        component: lazy(() => import('../page/admin/ShowReservations').then(({ShowsReservations}) =>({default:ShowsReservations}))),
        exact: true
    },
    {
        path: 'mon-panier',
        component: lazy(() => import('../page/admin/Carts').then(({Carts}) =>({default:Carts}))),
        exact: true
    },
    {
        path: 'tableau-de-bord',
        component: lazy(() => import('../page/admin/Dashboard').then(({Dashboard}) =>({default:Dashboard}))),
        exact: true
    },
    {
        path: 'tableau-de-bord-client',
        component: lazy(() => import('../page/client/dashboard/Layout').then(({Layout}) =>({default:Layout}))),
        exact: true
    },
    {
        path: 'valider-paiement',
        component: lazy(() => import('../page/admin/Validate').then(({Validate}) =>({default:Validate}))),
        exact: true
    },
    {
        path: 'liste-des-ressources',
        component: lazy(() => import('../page/admin/ShowResources').then(({ShowsResources}) =>({default:ShowsResources}))),
        exact: true
    },
    {
        path: 'facture',
        component: lazy(() => import('../components/invoice2/Invoice2').then(({Invoice2}) =>({default:Invoice2}))),
        exact: true
    },
    {
        path: 'ticket',
        component: lazy(() => import('../components/ticket/Ticket').then(({Ticket}) =>({default:Ticket}))),
        exact: true
    }
   


];

export default routes;