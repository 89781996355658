import React, { Component, lazy } from 'react'
import { boolean } from 'yup';
import {setAdmin1False, setAdmin1True} from "../storeAccessFunction/EnableOrDisableAdmins";


export const UserContext = React.createContext();

export class UserProvider extends Component {

    state = {
        users:[],
        langue:'',
        token:'',
        passwordtoken:''

    };
    addUser = (userId, name , email , role) =>{
        const {users} = this.state;
        let objUser = {userId, name , email , role};
        // replaces users.length element at index 0
        users.splice(0, users.length, objUser);
        this.setState({users: [...users]})

    }
    deconnecter = () =>{
        const {users} = this.state;
        users.splice(0, users.length);
        this.setState({users: users});
    };


    saveLangue = (language)=>{
        this.setState({langue: language})
    }
    addToken = (tokenUser)=>{
        this.setState({token: tokenUser})
    }

    addTokenPassword = (tokencheck) =>{
        this.setState({passwordtoken: tokencheck})
    }

    componentDidUpdate(){
        localStorage.setItem('dataUser', JSON.stringify(this.state.users))
        localStorage.setItem('dataLang', JSON.stringify(this.state.langue))
        localStorage.setItem('dataToken', JSON.stringify(this.state.token))
        localStorage.setItem('dataPasswordToken', JSON.stringify(this.state.passwordtoken))
    };
    componentDidMount(){
        const dataUser = JSON.parse(localStorage.getItem('dataUser'));
        const dataLang = JSON.parse(localStorage.getItem('dataLang'));
        const dataToken = JSON.parse(localStorage.getItem('dataToken'));
        const dataPasswordToken = JSON.parse(localStorage.getItem('dataPasswordToken'));
        if(dataUser !== null){
            this.setState({users: dataUser});
        }
        if(dataLang !== null){
            this.setState({langue: dataLang});
        }
        if(dataToken !== null){
            this.setState({token: dataToken});
        }
        if(dataPasswordToken !== null){
            this.setState({passwordtoken: dataPasswordToken});
        }
    }
    render() {
        const {users, langue , token, passwordtoken} = this.state;
        const {addUser , deconnecter , addToken, saveLangue } = this;
        return (
            <UserContext.Provider
                value={{addUser,users,deconnecter, token , langue, passwordtoken, addToken, saveLangue}}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}


