import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export const NotFoundPage = () => {

    const {t,i18n} = useTranslation()

    useEffect(()=>{
        const language = localStorage.getItem("language");
        if(language?.length > 0 ){
            i18n.changeLanguage(language).then(r => {})
        }
    },[])
    return (
        <div style={{marginTop:'300px'}}>
            <h1> Oups! La page recherchée n'existe pas. </h1>
        </div>
    )
}
