import { createSlice } from '@reduxjs/toolkit';

export const OpenModalReservation = createSlice({
    name: 'open',
    initialState: {
        value: false,
    },
    reducers: {
        on: (state) => {
            state.value = true
        },
        close: (state) => {
            state.value = false
        },
    },
})

export const { on, close } = OpenModalReservation.actions

export default OpenModalReservation.reducer