import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
const Lang = () => {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language);

    let changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
        let language = event.target.value;
                setLang(language);
                i18n.changeLanguage(language).then(r => {});
                localStorage.setItem("language", language);
    }

    useEffect(()=>{
            const language = localStorage.getItem("language");
            if(language?.length > 0 ){
                setLang(language);
                i18n.changeLanguage(language).then(r => {})
            }
    },[])
 
    return (
        <div>
            <div>
                <select className={'selectLanguage'} value={lang} name="language" onChange={changeLanguage}>
                    <option value={'fr'}>FR</option>
                    <option value={'en'}>EN</option>
                </select>
            </div>
        </div>
    )
}
 
export default Lang;