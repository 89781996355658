import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '../routes/Routes'; //Private Route list
import Loader from '../components/Loader';

const ProtectedRoutes = () => (
    <Switch>
        <Suspense
            fallback={<Loader />}
        >
            {routes.map(({ component: Component, path, exact }, index) => {
                return(
                    <Route
                        path={`/${path}`}
                        key={index}
                        exact={exact}
                    >
                        <Component  />
                    </Route>
                )
            } )}
        </Suspense>
    </Switch>
);

export default ProtectedRoutes;