
import Lang from "./Lang";
import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from '../page/admin/UserContext';
import  { useHistory, withRouter } from 'react-router-dom';
import MobileMenu from "./MobileMenu";
import {setCartBookingLength} from "../page/storeAccessFunction/cartBookingLength";
import {useDispatch, useSelector} from "react-redux";
import { DataContext } from "../page/admin/Context";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Navigation = (props) => {

  const {users , deconnecter} = useContext(UserContext);
  const dispatch = useDispatch()
  const {t,i18n} = useTranslation();
  const cartBookingLength = useSelector((state) => state.cartBookingLength.value)
  let history = useHistory();
  const onLogout = () => {
    deconnecter();
    history.push("/home");
  }
  const [ref, setRef] = useState(['home']);

  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }

  },[])
  useEffect(()=>{
    setRef(window.location.href.split('/'))
  },[window.location.href])


  return (
      <div>
        <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
          <div className='container'>
            <div className='navbar-header me-auto'>
              <div className={'mobil-menu-button'}>
                {( ref.length>0 && ref[ref.length-1]==='home')?
                    <div data-toggle="modal" data-target="#exampleModalLong">
                      <i className={'fa fa-bars'}></i>
                    </div>:
                    <div>
                      <i onClick={()=>history.goBack()} className={'fa fa-arrow-left'}></i>
                    </div>
                }
                <MobileMenu users = {users} onLogout = {()=>onLogout()}/>
              </div>
              <div>
                <a className='navbar-brand page-scroll' href='/home'>
                  <img src='/img/pategoubusinesscenter.png' alt=''/>
                </a>
              </div>
            </div>
            <div
                className='navbar-collapse collapse'
                id='bs-example-navbar-collapse-1'>
              <ul className='nav navbar-nav navbar-right'>
                <li className='dropdown'>
                  <a href='#' >
                    {t("Navigation-Menu-Coworking.translateTitre")+' '}
                    <i style={{fontWeight:'100'}} className='fa fa-chevron-down'/>
                  </a>
                  <ul style={{width:'123px'}}>
                    <li><a href='/espaces-coworking'>{t("Navigation-Menu-Nos-Espaces.translateTitre")}</a></li>
                    <li><a href='/services-coworking'>{t("Navigation-Menu-Nos-Services.translateTitre")}</a></li>
                  </ul>
                </li>
                <li className='dropdown' >
                  <a href='#' >
                    {t("Navigation-Menu-Consulting.translateTitre")+' '}
                    <i style={{fontWeight:'100'}} className='fa fa-chevron-down'/>

                  </a>
                  <ul style={{width:'125px'}}>
                    <li><a href='/conseil'>{t("Navigation-Menu-Conseil.translateTitre")}</a></li>
                    <li><a href='/incubateur'>{t("Navigation-Menu-Incubateur.translateTitre")}</a></li>

                  </ul>
                </li>
                <li>
                  <a href='/domiciliation' className='page-scroll'>
                    {t("Navigation-Menu-Domiciliation.translateTitre")}
                  </a>
                </li>
                <li>
                  <a href='/actualites' className='page-scroll'>
                    {t("Navigation-Menu-Actualité.translateTitre")}
                  </a>
                </li>
                <li>
                  <a href='/contacts' className='page-scroll'>
                    {t("Navigation-Menu-Contacts.translateTitre")}
                  </a>
                </li>
                <li className='language'>
                  <Lang  />
                </li>
                <li>
                  <a href="/espaces-coworking">
                    <button className="badge">{t("Navigation-Menu-Réserver.translateTitre")}</button>
                  </a>
                </li>
                <li>
                  <a href="/mon-panier">
                    <i className="fa fa-shopping-cart"></i>{' '}
                    <button className="badge">{users.length !== 0 ?JSON.parse(localStorage.getItem('basketLength')):0}</button>
                  </a>
                </li>
                {users.length === 0 ? (
                    <li>
                      <a href='/login'>
                        {t("Navigation-Menu-Se-Connecter.translateTitre")}
                      </a></li>
                ) : (<li className='dropdown'> <a href='#'><i className='fa fa-user'  aria-hidden="true" fa-lg></i></a>
                  <ul>
                    <li><a href={users[0].role==='USER'?'/tableau-de-bord-client':'/tableau-de-bord-admin'}>{t("Navigation-Menu-Mon-Compte.translateTitre")}</a></li>
                    <li><a style={{cursor:'pointer'}} onClick={onLogout}>{t("Navigation-Menu-Se-Deconnecter.translateTitre")}</a></li>
                  </ul>
                </li>  )}
              </ul>
            </div>
          </div>
        </nav>
      </div>
  )
}
export default withRouter(Navigation)