import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiConfig } from '../../config'

export const SaveAdditionalService = createSlice({
    name: 'additionalService',
    initialState: {
        value: [],
    },
    reducers: {
        addAdditionalServices: (state) => {
                axios.get(apiConfig.apiUrlAdditionalServices, { mode: 'no-cors' })
                    .then(result => {
                        state.value = result.data;
                    })
                    .catch(httpError => {
                        console.log('message', httpError)
                    })
            console.log('store', state.value)

        },
        },
    }
)

export const {addAdditionalServices } = SaveAdditionalService.actions

export default SaveAdditionalService.reducer