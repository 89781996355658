import { useTranslation } from "react-i18next";
import {useEffect, useState} from "react";
import axios from "axios";
import {apiConfig} from "../config";

export const Contact = (props) => {
  const [email, setEmail] = useState("");
  const [logMessage, setLogMessage] = useState(false)
  const [logMessageP, setLogMessageP] = useState(false)
  const [messageColor, setMessageColor] = useState('Light')
  const [message, setMessage] = useState('')
  const [messageRetour, setMessageRetour] = useState('')
  const handleChange = (input, e) => {
    if (input === "email") {
      setEmail(e.target.value);
    }
  }

  const sendNotification = (color, message, isUnderDate) => {
    setMessage(message)
    setMessageColor(color)
    if (isUnderDate) {
      setLogMessageP(true)
    } else {
      setLogMessage(true)
      setTimeout(() => {
        setLogMessage(false)
      }, 5000)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const messages = {
      email: email,
      source : "Business Center",
    }
    axios.post(apiConfig.apiMailNewsLetter, messages)
        .then(result => {
          setMessageRetour(`${t('newsletter.msg')}`)
        })
        .catch(exception => {
          setMessageRetour(`${t('newsletterError.msgError')}`)
        })
    setEmail("");

  };


  const {t,i18n} = useTranslation()

  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])

  return (
      <div>
        <div id='contact'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-3'>
                <div className='contact-item'>
                  <p>
              <span>
                <i className='fa fa-map-marker'></i> {t("Accueil-Contact-Footer-Adresse.translateTitre")}
              </span>
                    {t("Accueil-Contact-Footer-Adresse.translateTexte")}
                  </p>
                </div>
              </div>

              <div className='col-md-3'>
                <div className='contact-item'>
                  <p>
              <span>
                <i className='fa fa-phone'></i> {t("Accueil-Contact-Footer-Phone.translateTitre")}
              </span>{' '}
                    {t("Accueil-Contact-Footer-Phone.translateTexte")}<br/>
                    <i className='fa fa-whatsapp'></i>  {t("Accueil-Contact-Footer-Phone1.translateTexte3")}
                  </p>

                </div>
              </div>
              <div className='col-md-3'>
                <div className='contact-item'>
                  <p>
              <span>
                <i className='fa fa-envelope-o'></i> {t("Accueil-Contact-Footer-Mail.translateTitre")}
              </span>{' '}
                    {t("Accueil-Contact-Footer-Mail.translateTexte")}
                  </p>
                </div>
              </div>
              <div className='col-md-3 contact-newsletter'>
                <h4>{t("Accueil-Contact-Footer-NewsLetter.translateTitre")}</h4>
               <p style={{color:"white"}}>{messageRetour}</p>
                <form onSubmit={handleSubmit}>
                  <input

                      onChange={(e) => handleChange("email", e)}
                      placeholder={t("Accueil-Contact-Footer-NewsLetter.translateTexte")}
                      type='email'
                      name='email'
                      required/>
                  <input type='submit' value={t("Accueil-Contact-Footer-NewsLetter.translateTexteButton")}/>
                </form>
              </div>
            </div>

            <div className='col-md-12'>
              <div className='row'>

                <div className='social'>
                  <h4 style={{color:'white'}}>{t("Accueil-Contact-Footer-Reseau.translateTitre")}</h4>
                  <ul>
                    <li>
                      <a target={'_blank'} href={props.data ? props.data.twitter : '/'}>
                        <i className='fa fa-twitter'></i>
                      </a>
                    </li>
                    <li>
                      <a target={'_blank'} href={props.data ? props.data.facebook : '/'} rel={'noreferrer'}>
                        <i className='fa fa-facebook'></i>
                      </a>
                    </li>
                    <li>
                      <a target={'_blank'} href={props.data ? props.data.linkedin : '/'} rel={'noreferrer'}>
                        <i className='fa fa-linkedin'></i>
                      </a>
                    </li>
                    <li>
                      <a target={'_blank'} href={props.data ? props.data.instagram : '/'} rel={'noreferrer'}>
                        <i className='fa fa-instagram'></i>
                      </a>
                    </li>
                    <li>
                      <a href='https://wa.me/237657253737'>
                        <i className='fa fa-whatsapp'></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='footer'>
          <div className='container text-center'>
            <p>
              &copy; 2022 {t("Accueil-Contact-Footer-Reseau.designed")+' '}
              <a target={'_blank'} href='https://consulting.pategou.com' rel='nofollow'>
                Pategou Consulting
              </a>
            </p>
          </div>
        </div>
      </div>
  )
}