import { configureStore } from '@reduxjs/toolkit'
import OpenModalReducer from '../src/page/storeAccessFunction/OpenModalReservation'
import AdditionalServiceReducer from '../src/page/storeAccessFunction/SaveAdditionalService'
import setCartBookingLength from "./page/storeAccessFunction/cartBookingLength";
import EnableOrDisableAdminsReducer from "./page/storeAccessFunction/EnableOrDisableAdmins";

export default configureStore({
    reducer: {
        open: OpenModalReducer,
        additionalServices : AdditionalServiceReducer,
        cartBookingLength : setCartBookingLength,
        isAdmin1 : EnableOrDisableAdminsReducer
    },
})
