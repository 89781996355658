import * as React from 'react';
import Lang from "./Lang";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export default function MobileMenu(props) {

    const {users, onLogout} = props;
    const {t,i18n} = useTranslation()

    useEffect(()=>{
        const language = localStorage.getItem("language");
        if(language?.length > 0 ){
            i18n.changeLanguage(language).then(r => {})
        }
    },[])
    return (
        <div>
            <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button style={{marginTop: '2%', float:'left'}} type="button" className="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span style={{fontSize: '50px'}} aria-hidden="true">&times;</span>
                            </button>
                            <a className='navbar-brand page-scroll' href='/home'>
                                <img src='/img/newLogo.png' alt=''/>
                            </a>
                        </div>
                        <div class="modal-body ">
                            <nav id='navbar' className='navbar nav-mobile-menu'>
                                <ul>
                                    <li className='dropdown'><a className={'nav-link scrollto'} href='#'><span>{t("Navigation-Menu-Coworking.translateTitre")+' '}</span> <i className='bi bi-chevron-down'/></a>
                                        <ul>
                                            <li><a href='/espaces-coworking'>{t("Navigation-Menu-Nos-Espaces.translateTitre")}</a></li>
                                            <li><a href='/services-coworking'>{t("Navigation-Menu-Nos-Services.translateTitre")}</a></li>
                                        </ul>
                                    </li>
                                    <li className='dropdown'><a className={'nav-link scrollto'} href='#'><span>{t("Navigation-Menu-Consulting.translateTitre")}</span> <i className='bi bi-chevron-down'/></a>
                                        <ul>
                                            <li><a href='/conseil'>{t("Navigation-Menu-Conseil.translateTitre")}</a></li>
                                            <li><a href='/incubateur'>{t("Navigation-Menu-Incubateur.translateTitre")}</a></li>
                                        </ul>
                                    </li>
                                    <li><a className={'nav-link scrollto'} href='/domiciliation'> {t("Navigation-Menu-Domiciliation.translateTitre")}</a></li>
                                    <li><a className={'nav-link scrollto'} href='/actualites'>{t("Navigation-Menu-Actualité.translateTitre")}</a></li>
                                    <li><a className={'nav-link scrollto'} href='/contacts'>{t("Navigation-Menu-Contacts.translateTitre")}</a></li>
                                    <li className='dropdown selectLang'>
                                        <Lang />
                                    </li>
                                    <li>
                                        <a href="/espaces-coworking">
                                            <button className="badge">{t("Navigation-Menu-Réserver.translateTitre")}</button>
                                        </a>
                                    </li>
                                    <li>
                                        <a className={'nav-link scrollto'} href="/mon-panier">
                                            {t("Navigation-Menu-basket.translateTitre")}
                                            <button className="badge">{JSON.parse(localStorage.getItem('basketLength'))}</button>
                                        </a>
                                    </li>
                                    {users.length === 0 ? (
                                        <li>
                                            <a className={'nav-link scrollto'} href='/login'>
                                                {t("Navigation-Menu-Se-Connecter.translateTitre")}
                                            </a>
                                        </li>
                                    ) : (
                                        <>
                                            <li><a className={'nav-link scrollto'} href={users[0].role==='USER'?'/tableau-de-bord-client':'/tableau-de-bord-admin'}>{t("Navigation-Menu-Mon-Compte.translateTitre")}</a></li>
                                            <li><a className={'nav-link scrollto'} style={{cursor:'pointer'}} onClick={onLogout} data-dismiss="modal">{t("Navigation-Menu-Se-Deconnecter.translateTitre")}</a></li>
                                        </>
                                     )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
