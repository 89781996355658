import { createSlice } from '@reduxjs/toolkit'

export const SaveCartBookingLength = createSlice({
        name: 'cartBookingLength',
        initialState: {
            value: 0,
        },
        reducers: {
            setCartBookingLength: (state,value) => {
                state.value = value.payload;
            },
        },
    }
)

export const {setCartBookingLength} = SaveCartBookingLength.actions

export default SaveCartBookingLength.reducer