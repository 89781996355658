import {
    Route,
    Redirect
} from 'react-router-dom';

function PrivateRoute({ children, isAuthenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={
                ({ location }) => (
                    isAuthenticated
                        ? (
                            children
                        ) : (
                            <Redirect
                                from={location} to="login" exact
                            />
                        ))
            }
        />
    );
}

export default PrivateRoute;