import { createSlice } from '@reduxjs/toolkit';

export const EnableOrDisableAdmins = createSlice({
    name: 'isAdmin1',
    initialState: {
        value: false,
    },
    reducers: {
        setAdmin1True: (state) => {
            state.value = true
        },
        setAdmin1False: (state) => {
            state.value = false
        },
    },
})


// Action creators are generated for each case reducer function
export const { setAdmin1True, setAdmin1False } = EnableOrDisableAdmins.actions

export default EnableOrDisableAdmins.reducer
