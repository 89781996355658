import {Route} from 'react-router-dom';

function PublicRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={
                ({ location }) => (
                     (
                        children
                    ))
            }
        />
    );
}

export default PublicRoute;